import AddressUpdateForm from '@forms/AddressUpdateForm';
import PersonalDetailsForm from '@forms/PersonalDetailsForm';
import OrdersView from 'feature/profile/fragment/Orders';
import PasswordReset from 'feature/profile/fragment/PasswordReset';
import BusinessDetailsForm from 'feature/profile/fragment/ProfileBusinessSettings';
import ProfileRemoval from 'feature/profile/fragment/ProfileRemoval';
import SubscriptionView from 'feature/profile/fragment/Subscriptions';

export const ProfileData = [
  {
    label: 'Adressen',
    href: 'adressen',
    content: (setSelectedIndex) => <AddressUpdateForm setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Bedrijfsgegevens',
    href: 'bedrijfsgegevens',
    content: (setSelectedIndex) => <BusinessDetailsForm setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Bestellingen',
    href: 'bestellingen',
    content: (setSelectedIndex) => <OrdersView setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Gegevens',
    href: 'gegevens',
    content: (setSelectedIndex) => <PersonalDetailsForm setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Licentie',
    href: 'licentie',
    content: (setSelectedIndex) => <SubscriptionView setSelectedIndex={setSelectedIndex} />,
  },

  //TODO: Add this when they say it can be used again this will display the newsletters in the profile.
  // {
  //   label: 'Nieuwsbrieven',
  //   href: 'nieuwsbrieven',
  //   content: (setSelectedIndex) => <EmailOptions setSelectedIndex={setSelectedIndex} />,
  // },
  {
    label: 'Wijzig wachtwoord',
    href: 'wijzig wachtwoord',
    content: (setSelectedIndex) => <PasswordReset setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Privacy',
    href: 'Privacy',
    content: (setSelectedIndex) => <ProfileRemoval setSelectedIndex={setSelectedIndex} />,
  },
];
