import OrganizationSubscriptionDetails from './OrganizationSubscriptionDetails';

export default function OrganizationSubscriptionItem({
  licenseType,
  periodInterval,
  subscriptionHolder,
  referenceNumber,
}) {
  return (
    <section className="flex w-full p-4 md:p-8">
      {/* SMALL */}
      <div className="w-full md:hidden">
        <div className="flex flex-col">
          <h3 className="py-2 font-primary text-lg font-light text-primary-dark md:text-2xl lg:text-2xl">
            {licenseType}
          </h3>
          <div className="flex flex-col font-secondary font-light">
            <OrganizationSubscriptionDetails
              label="Betaling"
              value={periodInterval === 'year' ? 'Jaarlijks' : 'Maandelijks'}
            />
            <OrganizationSubscriptionDetails label="Abonnementhouder" value={subscriptionHolder} />
            <OrganizationSubscriptionDetails label="Referentie" value={referenceNumber} />
          </div>
        </div>
      </div>
      {/* MD+ */}
      <div className="hidden w-full md:flex">
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="flex flex-col justify-between pl-4">
            <div>
              <h3 className="py-2 font-primary text-xl font-light text-primary-dark md:text-2xl lg:text-2xl">
                {licenseType}
              </h3>
              <div className="flex flex-col font-secondary font-light">
                <OrganizationSubscriptionDetails
                  label="Betaling"
                  value={periodInterval === 'year' ? 'Jaarlijks' : 'Maandelijks'}
                />
                <OrganizationSubscriptionDetails
                  label="Abonnementhouder"
                  value={subscriptionHolder}
                />
                <OrganizationSubscriptionDetails label="Referentie" value={referenceNumber} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
