function getDate(publicationDate) {
  if (!publicationDate) {
    return;
  }

  // Check if publicationDate is an array
  if (Array.isArray(publicationDate)) {
    publicationDate = publicationDate[0];
  }

  let date;

  // Weird format from API
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/.test(publicationDate)) {
    // If it matches ISO 8601 format
    let dateString = publicationDate?.match(/^\d{4}-\d{2}-\d{2}/)[0];
    let parts = dateString.split('-');
    date = `${parts[2]}-${parts[1]}-${parts[0]}`;
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(publicationDate)) {
    let dateString = publicationDate?.match(/^\d{4}-\d{2}-\d{2}/)[0];
    let parts = dateString.split('-');
    date = `${parts[2]}-${parts[1]}-${parts[0]}`;
  } else if (/^\d{2}-\d{2}-\d{4}$/.test(publicationDate)) {
    // Format is "%d-%m-%Y"
    const dateParts = publicationDate.split('-');
    date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  } else if (/^\d{2}-\d{2}-\d{2}$/.test(publicationDate)) {
    // Format is "%d-%m-%y"
    const dateParts = publicationDate.split('-');
    const year = parseInt(dateParts[2], 10) + 2000; // Assuming dates in "yy" format are 21st century
    date = new Date(`${year}-${dateParts[1]}-${dateParts[0]}`);
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(publicationDate)) {
    // Format is "%Y-%m-%d"
    date = new Date(publicationDate);
  } else {
    // If format is not recognized, return undefined
    return;
  }

  let formattedDate = date.toLocaleString('nl-NL', {
    month: 'long',
    year: 'numeric',
  });

  return formattedDate.toLowerCase();
}

export { getDate };
