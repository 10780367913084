import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';
import { serverSideApi } from './serverSideApi';

export async function performClientSearch(body) {
  try {
    return await clientApi('/search/query?type=document', 'POST', null, JSON.stringify(body));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function performServerSearch(req, res, body) {
  try {
    return await serverSideApi(
      req,
      res,
      '/search/query?type=document',
      'POST',
      null,
      JSON.stringify(body),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}
