import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { OrganizationData } from '@helpers/organizationData';
import { ProfileData } from '@helpers/profileData';
import WithClaim from 'components/auth/WithClaim';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export default function UserMobileMenu({
  profileBarOpen,
  Fragment,
  setSidebarOpen,
  fullName,
  setProfileBarOpen,
}) {
  const [claims] = userStore((state) => [state.claims]);
  const [filteredProfileData, setFilteredProfileData] = useState(ProfileData);

  useEffect(() => {
    // Used only for admin role so they can't see that an order is placed on their personal account.
    let updatedProfileData = [...ProfileData];

    if (claims.includes('enterprise_admin') || claims.includes('platform_admin')) {
      updatedProfileData = updatedProfileData.filter((_, index) => index !== 2);
    }

    setFilteredProfileData(updatedProfileData);
  }, [claims]);

  return (
    <Transition.Root show={profileBarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-auto bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-center gap-8">
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => setProfileBarOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <Icon iconName="x" size="sm" color="text-primary-dark" />
                          </button>
                        </div>
                        <Dialog.Title className="font-primary text-lg font-black leading-5 text-primary-dark">
                          Hallo, {fullName}
                        </Dialog.Title>
                      </div>
                    </div>
                    {/* Sidebar Content */}
                    <div className="h-full p-6">
                      {/* Top content */}
                      <div className="flex flex-col gap-2 ">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex justify-between text-primary-dark hover:text-accent">
                                <div className="flex items-center gap-4">
                                  <Icon iconName="user" />
                                  <div className="font-secondary text-lg font-black">
                                    Mijn Profiel
                                  </div>
                                </div>
                                {open ? (
                                  <Icon iconName={'arrow-down'} />
                                ) : (
                                  <Icon iconName={'arrow-right'} />
                                )}
                              </Disclosure.Button>
                              <Disclosure.Panel>
                                {filteredProfileData.map((tab) => (
                                  <Link
                                    key={tab.label}
                                    href={`/profile?state=${tab.href}`}
                                    className="flex flex-col px-8 py-2 font-secondary text-lg text-primary-dark hover:text-accent"
                                    onClick={() => {
                                      setProfileBarOpen(false);
                                    }}
                                  >
                                    {tab.label}
                                  </Link>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                      {/* Organization Transition */}
                      <WithClaim
                        claim={['enterprise_admin', 'organization_admin']}
                        altComponent={null}
                      >
                        <div className="flex flex-col gap-2 ">
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="flex justify-between pt-3 text-primary-dark hover:text-accent">
                                  <div className="flex items-center gap-4">
                                    <Icon iconName="building" />
                                    <div className="font-secondary text-lg font-black">
                                      Mijn organisatie
                                    </div>
                                  </div>
                                  {open ? (
                                    <Icon iconName={'arrow-down'} />
                                  ) : (
                                    <Icon iconName={'arrow-right'} />
                                  )}
                                </Disclosure.Button>
                                <Disclosure.Panel>
                                  {OrganizationData.map((tab) => (
                                    <Link
                                      key={tab.label}
                                      href={`/organization?state=${tab.href}`}
                                      className="flex flex-col px-8 py-2 font-secondary text-lg text-primary-dark hover:text-accent"
                                      onClick={() => {
                                        setProfileBarOpen(false);
                                      }}
                                    >
                                      {tab.label}
                                    </Link>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      </WithClaim>

                      <WithClaim claim="platform_admin" altComponent={null}>
                        <div className="flex justify-between pt-4 text-primary-dark hover:text-accent">
                          <div className="flex gap-3">
                            <Icon iconName="folder-gear" />
                            <Link href="/admin" className="font-secondary text-lg font-black">
                              Beheer
                            </Link>
                          </div>
                          <Icon iconName={'arrow-right'} />
                        </div>
                      </WithClaim>
                      {/*TODO Implement last seen feature */}
                      {/* <div className="flex justify-between pt-4 text-primary-dark hover:text-accent">
                        <div className="flex gap-3">
                          <Icon iconName="eye" />
                          <Link href="/" className="font-secondary text-lg font-black">
                            Laatst Bekeken
                          </Link>
                        </div>
                        <Icon iconName={'arrow-right'} />
                      </div> */}
                      <div className="flex justify-between pt-4 text-primary-dark hover:text-accent">
                        <div className="flex gap-3 ">
                          <Icon iconName="power-off" />
                          <Link
                            href="/profile/logout"
                            className="font-secondary text-lg font-black"
                          >
                            Uitloggen
                          </Link>
                        </div>
                        <Icon iconName={'arrow-right'} />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
