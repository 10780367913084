/**
 * Function to sort an array alphabetically. Also accepts a secondary array for sorting order.
 * @param {Array<{}>} array - Array to be sorted.
 * @param {Array<String>} sortingArray - The array containing the order in which to sort the search results.
 * @param {string} filter - The key of the object for the function to sort on.
 */

function sortArrayByArray(array, sortingArray, filter) {
  if (!Array.isArray(array) || typeof filter !== 'string' || !Array.isArray(sortingArray)) {
    throw new Error(
      'Invalid input: array should be an array of objects, sortingArray should be an array of strings, and filter should be a string',
    );
  } else {
    sortingArray.reverse();
    const sortedArray = array.sort((a, b) => a[filter].localeCompare(b[filter]));
    const result = sortedArray.sort(
      (a, b) => sortingArray.indexOf(b[filter]) - sortingArray.indexOf(a[filter]),
    );
    return result;
  }
}

export { sortArrayByArray };
