import ProfileSubscriptionItem from './ProfileSubscriptionItem';
import ProfileSubscriptionSummary from './ProfileSubscriptionSummary';

export default function ProfileSubscriptionCard(profileSubscription) {
  return (
    <div className="rounded-md bg-white shadow-lg ">
      <ProfileSubscriptionSummary {...profileSubscription} />
      {profileSubscription?.subscriptionItems.map((item, index) => {
        item = {
          ...item,
          productName: profileSubscription?.licenseType,
          referenceNumber: profileSubscription?.referenceNumber,
        };
        return (
          <div key={index} className="border-t">
            <ProfileSubscriptionItem {...item} />
          </div>
        );
      })}
    </div>
  );
}
