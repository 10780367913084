import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import ListBox from '@elements/forms/ListBox';
import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { getProfileData, updateProfile } from '@providers/profile';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { validationSchema } from './validation';

export default function BusinessSettingsModalForm({
  industries,
  setShowModal,
  profile,
  setProfileData,
  setIndustry,
  industry,
}) {
  const [isValid, setIsValid] = useState({});
  const [industryValid, setIndustryValid] = useState(true);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  function handleIndustryChange(choice) {
    setIndustry(choice.value);
    customIndustryValidation(choice.value);
  }

  function customIndustryValidation(value) {
    if (value === industries.choices[0].value) {
      setIndustryValid(false);
      setIsValid({ ...isValid, industry: false });
      return false;
    } else {
      setIndustryValid(true);
      setIsValid({ ...isValid, industry: true });
      return true;
    }
  }

  async function onSubmit(formData) {
    if (!customIndustryValidation(industry)) {
      return;
    }

    try {
      if (!profile) {
        return;
      }

      const updatedProfile = {
        organization_name: formData.organization_name,
        branch_name: industry,
        branch_location: formData.branchLocation ?? null,
        chamber_of_commerce_number: formData.chamber_of_commerce_number,
        vat_number: formData.vat_number,
        billing_email: formData.billing_email,
        branch_number: formData.branchNumber ?? null,
      };

      let response;
      response = await updateProfile(updatedProfile);

      if (response && response.status === 200) {
        response = await getProfileData();
        if (response && response.status === 200) {
          setProfileData(response.data);
        }
      }
      setShowModal(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  }

  return (
    <form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        <InputField
          id="organization_name"
          label="Bedrijfsnaam*"
          name="organization_name"
          type="text"
          register={register}
          errors={errors}
          message={errors.organization_name?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.organization_name);
          }}
          isValid={isValid['organization_name']}
          defaultValue={profile?.organization_name || ''}
        />
        <ListBox
          choices={industries.choices}
          label={industries.label}
          onChange={handleIndustryChange}
          errors={!industryValid}
          isValid={isValid['industry']}
          value={industry}
        />
        {!industryValid && (
          <div className="font-primary text-sm font-light tracking-wide text-functional-error">
            Branche is verplicht
          </div>
        )}
        <InputField
          id="branchLocation"
          label="Vestigingslocatie"
          name="branchLocation"
          type="text"
          register={register}
          errors={errors}
          message={errors.branchLocation?.message}
          onKeyUp={(e) => {
            handleValidationWrapper(e, errors?.branchLocation);
          }}
          isValid={isValid['branchLocation']}
          defaultValue={profile?.branch_location || ''}
        />
        <InputField
          id="chamber_of_commerce_number"
          label="KvK-nummer*"
          name="chamber_of_commerce_number"
          type="text"
          register={register}
          errors={errors}
          message={errors.chamber_of_commerce_number?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.chamber_of_commerce_number);
          }}
          isValid={isValid['chamber_of_commerce_number']}
          defaultValue={profile?.chamber_of_commerce_number || ''}
        />
        <InputField
          id="branchNumber"
          label="Vestigingsnummer"
          name="branchNumber"
          type="text"
          register={register}
          errors={errors}
          message={errors.branchNumber?.message}
          onKeyUp={(e) => {
            handleValidationWrapper(e, errors?.branchNumber);
          }}
          isValid={isValid['branchNumber']}
          defaultValue={profile?.branch_number || ''}
        />
        <InputField
          id="vat_number"
          label="BTW-nummer*"
          name="vat_number"
          type="tel"
          register={register}
          errors={errors}
          message={errors.vat_number?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.vat_number);
          }}
          isValid={isValid['vat_number']}
          defaultValue={profile?.vat_number || ''}
        />
        <InputField
          id="billing_email"
          label="Factuur E-mail"
          name="billing_email"
          type="text"
          register={register}
          errors={errors}
          message={errors.billing_email?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.billing_email);
          }}
          isValid={isValid['billing_email']}
          defaultValue={profile?.billing_email || ''}
        />
      </div>
      <div className="flex justify-end gap-4">
        <Button
          label="Annuleren"
          type="button"
          buttonType="secondary"
          onClick={() => setShowModal(false)}
        />
        <Button label="Versturen" type="submit" buttonType="primary" />
      </div>
    </form>
  );
}
