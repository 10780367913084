import { formValidators } from '@forms/validators';
import { getRegionForPhoneNumber, validatePhoneNumber } from '@helpers/phoneValidation';
import * as Yup from 'yup';

const isValidCountryCode = (countryCode, phoneNumber) => {
  const region = getRegionForPhoneNumber(phoneNumber);
  return typeof countryCode === 'string' && countryCode.length > 0 && region === countryCode;
};

Yup.addMethod(Yup.string, 'phone', function (strict = false, errorMessage = '') {
  const errMsg =
    typeof errorMessage === 'string' && errorMessage ? errorMessage : 'Ongeldig telefoonnummer';

  return this.test('phone', errMsg, function (value) {
    const { path, createError } = this;

    // Check if the phone number's region matches the provided country code
    if (!isValidCountryCode(getRegionForPhoneNumber(value), value)) {
      return createError({ path, message: errMsg });
    }

    // Perform normal phone number validation
    const isValid = validatePhoneNumber(value, getRegionForPhoneNumber(value), strict);

    if (!isValid) {
      return createError({ path, message: errMsg });
    }

    return true;
  });
});

export const validationSchema = Yup.object().shape({
  organizationEmail: formValidators.email,
  organizationContactNumber: Yup.string().phone(),
  billingEmail: formValidators.email,
  contractHolderEmail: formValidators.email,
  contractHolder: Yup.string().required('Naam van de contracthouder is vereist'),
});
