import LinkButton from '@elements/buttons/LinkButton';
import Container from '@elements/Container';
import Icon from '@elements/Icon';
import NextLink from '@elements/links/NextLink';
import logo from '@elements/Logo';
import { useEnvContext } from '@helpers/envProvider';
import { usePlausible } from 'next-plausible';

const data = {
  address: {
    title: 'Bezoekadres',
    details: 'Weena 505',
    detailsTwo: '3013 AL Rotterdam',
  },
  postAddress: {
    title: 'Postadres',
    details: 'BouwZo',
    detailsTwo: 'Postbus 2838',
    detailsThree: '3000 CV Rotterdam',
  },
  vatNumber: {
    title: 'BTW-nummer',
    details: 'NL818111240B01',
  },
  chamberOfCommerceNumber: {
    title: 'KvK-nummer',
    details: '24416420',
  },
  socials: {
    title: 'Volg ons op',
    details: [
      { logo: 'twitter', href: 'https://twitter.com/issonieuws' },
      { logo: 'linkedin', href: 'https://nl.linkedin.com/company/isso' },
    ],
  },
  phone: '+ 31 (0)10 206 59 60',
  email: 'info@bouwzo.nl',
  newsletter: 'Aanmelden nieuwsbrief',
  links: [
    { label: 'Algemene voorwaarden', href: 'https://over.bouwzo.nl/algemene-voorwaarden/' },
    { label: 'Gebruiksvoorwaarden', href: 'https://over.bouwzo.nl/gebruiksvoorwaarden' },
    { label: 'Privacy', href: '/legal/privacy' },
    { label: 'Cookies', href: '/legal/cookies' },
    { label: 'Klantenservice', href: '/contact' },
  ],
};

export default function Footer() {
  const { environment } = useEnvContext();
  const NEXT_PUBLIC_VERSION = environment['NEXT_PUBLIC_VERSION'];
  const logoData = logo;
  const plausibleEvent = usePlausible();

  return (
    <Container variant="primary">
      <footer>
        {/* SM */}
        <div className="flex flex-col gap-4 bg-primary py-6 md:hidden md:px-4">
          <logoData.bouwzo.svg className="w-[72px] text-primary-dark" />
          <address className="flex flex-col font-secondary not-italic text-primary-dark">
            <div className="font-secondary font-black text-primary-dark">{data.address.title}</div>
            <div>{data.address.details}</div>
            <div>{data.address.detailsTwo}</div>
          </address>
          <address className="flex flex-col font-secondary not-italic text-primary-dark">
            <div className="font-black">{data.postAddress.title}</div>
            <div>{data.postAddress.details}</div>
            <div>{data.postAddress.detailsTwo}</div>
            <div>{data.postAddress.detailsThree}</div>
          </address>
          <div className="flex flex-col">
            <div className="font-secondary font-black text-primary-dark">
              {data.vatNumber.title}
            </div>
            <div className="font-secondary text-primary-dark">{data.vatNumber.details}</div>
          </div>
          <div className="flex flex-col">
            <div className="font-secondary font-black text-primary-dark">
              {data.chamberOfCommerceNumber.title}
            </div>
            <div className="font-secondary text-primary-dark">
              {data.chamberOfCommerceNumber.details}
            </div>
          </div>
          <a href={`tel:${data.phone}`} className="flex gap-4">
            <Icon iconName="phone" color="text-primary-dark" />
            <div className="font-secondary text-primary-dark underline">{data.phone}</div>
          </a>
          <a href={`mailto:${data.email}`} className="-mt-2 flex gap-4">
            <Icon iconName="mail" color="text-primary-dark" />
            <div className="font-secondary text-primary-dark underline">{data.email}</div>
          </a>
          <a
            href={'https://over.bouwzo.nl/inschrijven-nieuwsbrief/'}
            target="_blank"
            className="-mt-2 flex gap-4"
            rel="noreferrer"
          >
            <Icon iconName="newspaper" color="text-primary-dark" />
            <div className="font-secondary text-primary-dark underline">{data.newsletter}</div>
          </a>
          <div className="flex flex-col">
            <div className="pb-2 font-primary font-bold text-primary-dark">
              {data.socials.title}
            </div>
            <div className="flex gap-2">
              {!!data.socials.details &&
                data.socials.details?.map((social) => (
                  <LinkButton
                    onClick={() => {
                      if (social.logo === 'twitter') {
                        plausibleEvent('Twitter Share');
                      } else if (social.logo === 'linkedin') {
                        plausibleEvent('LinkedIn Share');
                      }
                    }}
                    key={social.logo}
                    style={{ WebkitTapHighlightColor: 'transparent' }}
                    buttonType="secondary"
                    href={social.href}
                    target="_blank"
                    icon={social.logo}
                    overrideClass="!rounded-full h-10 w-10 !text-primary !bg-primary-dark"
                  />
                ))}
            </div>
          </div>
          <hr className="border-primary-dark/70" />
          <nav className="flex flex-col gap-2 font-secondary text-primary-dark underline">
            {!!data.links &&
              data.links?.map((link) => (
                <NextLink key={link.href} href={link.href} scroll={true}>
                  {link.label}
                </NextLink>
              ))}
          </nav>
          <div className="font-secondary text-sm font-light text-primary-dark">
            Versie: {NEXT_PUBLIC_VERSION}
          </div>
        </div>
        {/* MD+ */}
        <div className="hidden flex-col bg-primary md:flex md:py-7 lg:pt-14">
          <logoData.bouwzo.svg className="w-[72px] pb-8 text-primary-dark" />
          <div className="flex md:gap-24 lg:justify-between">
            <div className="flex flex-col justify-between gap-6">
              <address className="flex flex-col font-secondary font-light not-italic text-primary-dark">
                <div className="font-secondary text-lg font-black text-primary-dark">
                  {data.address.title}
                </div>
                <div>{data.address.details}</div>
                <div>{data.address.detailsTwo}</div>
              </address>
              <div className="flex pb-4">
                <a href={`tel:${data.phone}`} className="flex items-center gap-4">
                  <Icon iconName="phone" color="text-primary-dark" />
                  <div className="font-secondary text-primary-dark underline">{data.phone}</div>
                </a>
              </div>
            </div>
            <div className="flex flex-col justify-between gap-8">
              <address className="flex flex-col font-secondary font-light not-italic text-primary-dark">
                <div className="font-secondary text-lg font-black text-primary-dark">
                  {data.postAddress.title}
                </div>
                <div>{data.postAddress.details}</div>
                <div>{data.postAddress.detailsTwo}</div>
                <div>{data.postAddress.detailsThree}</div>
              </address>
              <div className="flex pb-4">
                <a href={`mailto:${data.email}`} className="flex gap-4">
                  <Icon iconName="mail" color="text-primary-dark" />
                  <div className="font-secondary tracking-wide text-primary-dark underline">
                    {data.email}
                  </div>
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <div className="font-secondary text-lg font-black text-primary-dark">
                  {data.vatNumber.title}
                </div>
                <div className="font-light tracking-wide text-primary-dark">
                  {data.vatNumber.details}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-secondary text-lg font-black text-primary-dark">
                  {data.chamberOfCommerceNumber.title}
                </div>
                <div className="font-light tracking-wide text-primary-dark">
                  {data.chamberOfCommerceNumber.details}
                </div>
              </div>
              <div className="flex pb-4">
                <a
                  href={'https://over.bouwzo.nl/inschrijven-nieuwsbrief/'}
                  target="_blank"
                  className="flex gap-4"
                  rel="noreferrer"
                >
                  <Icon iconName="newspaper" color="text-primary-dark" />
                  <div className="font-secondary tracking-wide text-primary-dark underline">
                    {data.newsletter}
                  </div>
                </a>
              </div>
            </div>
            <div className="hidden flex-col lg:flex">
              <div className="pb-4 font-primary text-lg font-black text-primary-dark">
                {data.socials.title}
                <div className="flex gap-4 pt-2">
                  {!!data.socials.details &&
                    data.socials.details?.map((social) => (
                      <LinkButton
                        onClick={() => {
                          if (social.logo === 'twitter') {
                            plausibleEvent('Twitter Share');
                          } else if (social.logo === 'linkedin') {
                            plausibleEvent('LinkedIn Share');
                          }
                        }}
                        key={social.logo}
                        style={{ WebkitTapHighlightColor: 'transparent' }}
                        buttonType="secondary"
                        href={social.href}
                        target="_blank"
                        icon={social.logo}
                        overrideClass="!rounded-full size-10 text-primary !bg-primary-dark !text-primary"
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col py-8 md:flex lg:hidden">
            <div className="pb-4 font-bold text-primary-dark">{data.socials.title}</div>
            <div className="flex gap-4 ">
              {!!data.socials.details &&
                data.socials.details?.map((social) => (
                  <LinkButton
                    onClick={() => {
                      if (social.logo === 'twitter') {
                        plausibleEvent('Twitter');
                      } else if (social.logo === 'linkedin') {
                        plausibleEvent('LinkedIn');
                      }
                    }}
                    key={social.logo}
                    style={{ WebkitTapHighlightColor: 'transparent' }}
                    buttonType="secondary"
                    href={social.href}
                    target="_blank"
                    icon={social.logo}
                    overrideClass="!rounded-full size-10 text-primary !bg-primary-dark !text-primary"
                  />
                ))}
            </div>
          </div>
          <hr className="border-primary-dark/70" />
          <div className="flex justify-between">
            <div className="flex pt-2 font-secondary text-primary-dark underline md:gap-6 lg:gap-32">
              {!!data.links &&
                data.links?.map((link) => (
                  <NextLink key={link.href} href={link.href}>
                    {link.label}
                  </NextLink>
                ))}
            </div>
            <div className="pt-3 font-secondary text-sm font-light text-primary-dark">
              Versie: {NEXT_PUBLIC_VERSION}
            </div>
          </div>
        </div>
      </footer>
    </Container>
  );
}
