'use client';

import { userStore } from '@context/userStore';
import { useEffect, useState } from 'react';

const WithClaim = ({ claim = [], altComponent = null, children }) => {
  const claims = userStore((state) => state.claims);
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (!pageLoaded) {
    return null;
  }

  if (
    (Array.isArray(claim) && claim.some((c) => claims.includes(c))) ||
    (typeof claim === 'string' && claims.includes(claim))
  ) {
    return <>{children}</>;
  } else if (altComponent) {
    return <>{altComponent}</>;
  } else {
    return null;
  }
};

export default WithClaim;
