import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';

export default function ProfileLicenseShield({ bundleName, startDate, expirationDate, isActive }) {
  const formattedStartDate = startDate
    ? new Date(startDate).toLocaleDateString('nl-NL')
    : 'Geen startdatum';
  const formattedEndDate = expirationDate
    ? new Date(expirationDate).toLocaleDateString('nl-NL')
    : 'Geen einddatum';
  return (
    <div className="rounded-md bg-white p-4 shadow-lg md:px-6 md:py-4">
      <div className="flex items-center justify-between pb-1 font-bold tracking-wide sm:pb-2 md:text-lg">
        <div>{bundleName}</div>
        <div
          className={classNames(
            'size-5 rounded-full flex items-center justify-center',
            isActive
              ? 'bg-green-100 text-functional-completed'
              : 'bg-functional-error-light text-functional-error/60',
          )}
        >
          {isActive ? (
            <Icon iconName="check" size="xs" />
          ) : (
            <Icon iconName="x" size="xs" overrideClass="!text-[10px]" />
          )}
        </div>
      </div>
      <div className="container flex flex-row justify-between">
        <div className="text-xs md:text-sm">
          <div className="">{isActive ? 'Actief sinds' : 'Startte op'}</div>
          <div className="font-light">{formattedStartDate}</div>
        </div>
        <div className="text-xs md:text-sm">
          <div className="">{isActive ? 'Verloopt per' : 'Verlopen per'}</div>
          <div className="font-light">{formattedEndDate}</div>
        </div>
      </div>
    </div>
  );
}
