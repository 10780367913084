import { classNames } from '@helpers/classNames';
import { formatValue } from '@helpers/formatValue';

export function CurrencyValue(props) {
  const { label, negative, value, textSize } = props;

  const textStyles = {
    sm: 'text-sm',
    lg: 'text-lg font-bold',
  };

  const { formattedValue } = formatValue(value);

  return (
    <div className={classNames('flex justify-between py-2 font-secondary', textStyles[textSize])}>
      <dt className="text-primary-dark">{label}</dt>
      {negative ? (
        <dd className="flex gap-4">
          <span className="text-xl leading-5">-</span>
          {formattedValue}
        </dd>
      ) : (
        <dd>{formattedValue}</dd>
      )}
    </div>
  );
}
