import { usePlausible } from 'next-plausible';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import SearchBoxContext from './SearchBoxContext';

const searchBoxConfig = {
  primary: {
    showSuggestions: true,
    redirect: true,
    form: {
      style:
        'focus-within:outline focus-within:outline-primary-dark/70 md:ml-6 focus-within:border-1',
    },
    input: {
      placeholder: 'Waar ben je naar op zoek?',
      style: 'grow bg-white px-4 focus:outline-none rounded-none',
    },
  },
  secondary: {
    showSuggestions: false,
    redirect: true,
    form: {
      style: 'gap-2 md:gap-4',
    },
    input: {
      placeholder: 'Zoeken',
      style:
        'h-full rounded-md border border-primary-light px-4 focus:border-transparent focus:outline focus:outline-primary-light',
    },
    button: true,
  },
  tertiary: {},
};

const SearchBox = ({ type, onClick }) => {
  const router = useRouter();
  const plausibleEvent = usePlausible();

  function redirectSearchPage(query) {
    plausibleEvent('Zoekopdracht', { props: { query } });
    router.push({
      pathname: '/search',
      query: { query: query },
    });
  }

  function handleSearch(data) {
    redirectSearchPage(data.query);
  }

  return (
    <div role="button" tabIndex={0} onClick={onClick}>
      <link rel="stylesheet" type="text/css" href="/searchbox.css" />
      <SearchBoxContext handleSearch={handleSearch} config={searchBoxConfig[type]} />
    </div>
  );
};

SearchBox.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

SearchBox.defaultProps = {
  type: 'primary',
};

export default SearchBox;
