import Seats from 'feature/organization/Seats';
import 'react-toastify/dist/ReactToastify.css';
import OrganizationSubscriptionItem from './OrganizationSubscriptionItem';
import OrganizationSubscriptionSummary from './OrganizationSubscriptionSummary';

export default function OrganizationSubscriptionCard(organizationSubscription) {
  return (
    <div className="rounded-md bg-white shadow-lg ">
      <OrganizationSubscriptionSummary {...organizationSubscription} />
      <div className="border-t"></div>
      <OrganizationSubscriptionItem {...organizationSubscription} />
      <div className="mb-4 border-t"></div>
      <Seats
        used={organizationSubscription?.subscriptionMemberCount?.active}
        pending={organizationSubscription?.subscriptionMemberCount?.pending}
        total={organizationSubscription?.subscriptionMemberCount?.total}
      />
    </div>
  );
}
