import { formValidators } from '@forms/validators';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  billing_email: formValidators.email,
  organization_name: Yup.string().required('Veld is verplicht'),
  chamber_of_commerce_number: Yup.string()
    .required('Veld is verplicht')
    .matches(/^[0-9]*$/, 'Alleen cijfers zijn toegestaan')
    .length(8, 'Moet precies 8 cijfers zijn'),
  vat_number: Yup.string().required('Veld is verplicht'),
});
