import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import { handleValidation } from '@helpers/handleValidation';
import { logout } from '@helpers/logout';
import { yupResolver } from '@hookform/resolvers/yup';
import { deleteProfile } from '@providers/profile';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { validationSchema } from './validation';

export default function ProfileRemovalModalForm({ setShowModal }) {
  const [isValid, setIsValid] = useState({});
  const { setUserStore } = userStore();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    reset,
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  function resetModal() {
    reset();
    setIsValid({});
    setShowModal(false);
  }

  async function handleDeleteAccount(data) {
    const response = await deleteProfile(data.password);
    if (response && response.status === 200) {
      resetModal();
      await logout(setUserStore);
    } else {
      toast.error('Kan profiel niet verwijderen.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }

  return (
    <>
      <div className="mb-5 mt-2">
        Weet je zeker dat je je profiel wilt verwijderen? <br />
        <span className="font-bold underline">
          Let op: als je een licentie hebt, loopt deze gewoon door!
        </span>
      </div>
      <form
        className="container flex flex-col gap-4 font-primary"
        onSubmit={handleSubmit(handleDeleteAccount)}
      >
        <InputField
          label="Wachtwoord*"
          name="password"
          type="password"
          register={register}
          errors={errors}
          message={errors.password?.message}
          onKeyUp={(e) => handleValidationWrapper(e, errors.password)}
          isValid={isValid['password']}
        />
        <div className="flex justify-end gap-4">
          <Button
            label="Annuleren"
            type="button"
            buttonType="secondary"
            onClick={() => setShowModal(false)}
          />
          <Button
            label="Ja, verwijder mijn profiel"
            type="submit"
            buttonType="danger"
            disabled={!isDirty || !isValid['password']}
            overrideClass="text-white/90"
          />
        </div>
      </form>
    </>
  );
}
