import Icon from '@elements/Icon';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import Link from 'next/link';

export default function MobileMenu({
  setSidebarOpen,
  sidebarOpen,
  Fragment,
  logoData,
  documentDetails,
}) {
  return (
    <>
      <button type="button" onClick={() => setSidebarOpen(true)}>
        <Icon iconName="bars" color="text-primary-dark" size="2xl" />
      </button>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            s
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-700/75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-auto bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            <logoData.bouwzo.svg className="w-[72px] fill-primary-dark" />
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                              onClick={() => setSidebarOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <Icon iconName="x" color="text-primary-dark" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-8 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0 px-4 sm:px-6">
                          {/* Sidebar Content */}
                          <div className="h-full">
                            {/* Top content */}
                            <nav className="flex flex-col gap-4">
                              <div className="mb-2">
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="flex w-full justify-between rounded-lg text-left text-sm font-medium hover:underline">
                                        <div className="text-lg font-bold text-primary-dark">
                                          Categorieën
                                        </div>
                                        {open ? (
                                          <Icon iconName={'arrow-down'} color={'#000000'} />
                                        ) : (
                                          <span className="justify-between pt-1.5">
                                            <Icon iconName={'arrow-right'} color={'#000000'} />
                                          </span>
                                        )}
                                      </Disclosure.Button>

                                      <Disclosure.Panel>
                                        {documentDetails.categories.map((child) => (
                                          <Link
                                            key={child.id}
                                            href={child.href.toLowerCase()}
                                            className="flex flex-col py-2"
                                          >
                                            <span className="font-secondary text-sm text-primary-dark hover:text-accent hover:underline">
                                              {child.name}
                                            </span>
                                          </Link>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </div>
                              <div>
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="flex w-full justify-between rounded-lg text-left text-sm font-medium hover:underline">
                                        <div className="text-lg font-bold text-primary-dark">
                                          Producten
                                        </div>
                                        {open ? (
                                          <Icon iconName={'arrow-down'} color={'#000000'} />
                                        ) : (
                                          <span className="justify-between pt-1.5">
                                            <Icon iconName={'arrow-right'} color={'#000000'} />
                                          </span>
                                        )}
                                      </Disclosure.Button>
                                      <Disclosure.Panel>
                                        {documentDetails.documentTypes.map((child) => (
                                          <Link
                                            key={child.id}
                                            href={child.href}
                                            className="flex flex-col py-2"
                                          >
                                            <span className="text-sm text-primary-dark hover:text-accent hover:underline">
                                              {child.name}
                                            </span>
                                          </Link>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                                <br />
                                <Link href="https://over.bouwzo.nl" target="_blank">
                                  <div className="text-lg font-bold text-primary-dark hover:underline">
                                    Over BouwZo
                                  </div>
                                </Link>
                                <br />
                                <Link href="https://over.bouwzo.nl/nieuws-blogs/" target="_blank">
                                  <div className="text-lg font-bold text-primary-dark hover:underline">
                                    Nieuws
                                  </div>
                                </Link>
                                <br />
                                <Link href="/contact">
                                  <div className="text-lg font-bold text-primary-dark hover:underline">
                                    Support
                                  </div>
                                </Link>
                                <br />
                              </div>
                            </nav>
                          </div>
                        </div>
                        {/* /End replace */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
