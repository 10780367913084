import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';

export async function sendEnterpriseExtendSeatsRequest(payload) {
  try {
    return await clientApi(
      '/profile/enterprise/extend_seats_request',
      'POST',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function isOrganisationAdmin(profile_id = '') {
  try {
    return await clientApi(`/profile/enterprise/is_organization_admin/${profile_id}`, 'GET', null);
  } catch (error) {
    Sentry.captureException(error);
  }
}
