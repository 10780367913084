import Icon from '@elements/Icon';
import { Menu } from '@headlessui/react';
import { classNames } from '@helpers/classNames';

const baseStyle =
  'inline-flex h-11 w-full items-center justify-center gap-2.5 px-4 py-2 text-primary-dark focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 text-sm';

function PrimaryMenuButton(props) {
  const { label, open } = props;
  return (
    <Menu.Button
      className={classNames(
        baseStyle,
        'hover:bg-opacity-30 md:border md:border-primary-dark/50 rounded-md',
      )}
    >
      <Icon iconName="user" color="text-primary-dark" />
      <div className="hidden whitespace-nowrap font-bold md:block">{label}</div>
      <div className="hidden md:block">
        {open ? (
          <Icon iconName="chevron-up" color="text-primary-dark" />
        ) : (
          <Icon iconName="chevron-down" color="text-primary-dark" />
        )}
      </div>
    </Menu.Button>
  );
}

function SecondaryMenuButton(props) {
  const { label, open } = props;
  return (
    <Menu.Button
      className={classNames(baseStyle, 'rounded-md font-black hover:bg-black hover:bg-opacity-20')}
    >
      {label}
      {open ? (
        <Icon iconName="chevron-up" color="text-primary-dark" />
      ) : (
        <Icon iconName="chevron-down" color="text-primary-dark" />
      )}
    </Menu.Button>
  );
}

export { PrimaryMenuButton, SecondaryMenuButton };
