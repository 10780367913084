import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  organizationName: Yup.string().required('Veld is verplicht'),
  chamberOfCommerceNumber: Yup.string()
    .required('Veld is verplicht')
    .matches(/^[0-9]*$/, 'Alleen cijfers zijn toegestaan')
    .length(8, 'Moet precies 8 cijfers zijn'),
  vatNumber: Yup.string().required('Veld is verplicht'),
});
