import Button from '@elements/buttons/Button';

export default function IsOrganizationAdminModal({ setShowIsOrganizationAdminModal = () => {} }) {
  return (
    <>
      <div className="mb-5 mt-2">
        Je profiel kan niet worden verwijderd omdat je een organisatiebeheerder bent. Wijzig de
        beheerder van de organisatie en probeer het opnieuw.
      </div>

      <div className="flex justify-end gap-4">
        <Button
          label="Begrepen"
          type="submit"
          buttonType="primary"
          overrideClass="text-white/90"
          onClick={() => setShowIsOrganizationAdminModal(false)}
        />
      </div>
    </>
  );
}
