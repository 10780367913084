import { industries } from '@config/industries';
import { userStore } from '@context/userStore';
import DetailList from '@elements/DetailList';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import ProfileBusinessSettingsModalForm from '@forms/ProfileBusinessSettingsModalForm';
import { getProfileData } from '@providers/profile';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect, useState } from 'react';

const phoneUtil = PhoneNumberUtil.getInstance();

export default function BusinessDetailsForm() {
  const { profileId } = userStore();
  const [showModal, setShowModal] = useState(false);
  const [profile, setProfileData] = useState(null);
  const [industry, setIndustry] = useState(industries.choices[0].value);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');

  const onClickModal = (modal) => {
    if (modal === 'business') {
      setShowModal(!showModal);
    } else if (modal === 'contact') {
      setShowContactModal(!showContactModal);
    } else if (modal === 'admin') {
      setShowAdminModal(!showAdminModal);
    }
  };

  const getProfileInformation = async () => {
    const response = await getProfileData(profileId);

    if (response && response.status === 200) {
      setProfileData(response.data);

      const matchingIndustry = industries.choices.find(
        (choice) => choice.value === response.data.branch_name,
      );
      setIndustry(matchingIndustry?.value || industries.choices[0].value);
      setPhoneValue(response.data.contact_phone_number);
    }
  };

  useEffect(() => {
    getProfileInformation(profileId);
  }, [profileId]);

  let formattedProfileNumber;

  function formatToNational(phoneNum, countryCode) {
    const parsedNumber = phoneUtil.parse(phoneNum, countryCode);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
  }

  if (profile && profile.contact_phone_number) {
    const databaseNumberValue = profile.contact_phone_number;
    formattedProfileNumber = formatToNational(databaseNumberValue, 'NL');
  } else {
    formattedProfileNumber = phoneValue;
    console.log(formattedProfileNumber); // TODO remove later
  }

  const userCompanyDetails = [
    { label: 'Bedrijfsnaam', value: profile?.organization_name },
    { label: 'Branche', value: profile?.branch_name },
    { label: 'Vestigingslocatie', value: profile?.branch_location },
    { label: 'KvK-nummer', value: profile?.chamber_of_commerce_number },
    { label: 'Vestigingsnummer', value: profile?.branch_number },
    { label: 'BTW-nummer', value: profile?.vat_number },
    { label: 'Factuur E-mail', value: profile?.billing_email },
  ];

  const modalFormProps = {
    industries,
    setShowModal,
    profile,
    setProfileData,
    setIndustry,
    industry,
  };

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2 className="font-primary text-3xl">Bedrijfsgegevens</h2>
          <p className="mb-8 mt-1">Bekijk hier je bedrijfsgegevens.</p>
        </div>

        <div className="mt-1">
          <Button
            type="button"
            icon="pencil"
            buttonType="secondary"
            onClick={() => onClickModal('business')}
          />
        </div>
      </div>

      {profile ? <DetailList details={userCompanyDetails} /> : <p>Loading profile data...</p>}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Werk mijn bedrijfsgegevens bij"
        modalContent={<ProfileBusinessSettingsModalForm {...modalFormProps} />}
      />
    </div>
  );
}
