import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';

export async function createSubscription(body) {
  try {
    return await clientApi(
      '/ecommerce/orders/subscription/checkout',
      'POST',
      null,
      JSON.stringify(body),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getProfileSubscriptionDetails() {
  try {
    return await clientApi(`/ecommerce/orders/subscription`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getOrganizationSubscriptionDetails(organizationId) {
  try {
    return await clientApi(`/profile/enterprise/subscription/info/${organizationId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function sendEnterpriseSubscriptionQuoteRequest(payload) {
  try {
    return await clientApi(
      '/profile/enterprise/enterprise_subscription_quote',
      'POST',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getCheckoutConfirmationSubscriptionDetails(checkoutSessionId) {
  try {
    return await clientApi(
      `/ecommerce/orders/subscription/confirmation/${checkoutSessionId}`,
      'GET',
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}
