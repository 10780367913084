const CURRENCY = 'EUR';

export function formatValue(value) {
  if (!isNaN(value)) {
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      const formattedValue = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: CURRENCY,
      }).format(parsedValue);

      const [, cents] = formattedValue.split(',');

      return {
        formattedValue,
        cents: cents ? cents.trim() : '',
        isDateTime: false,
      };
    }
  }

  if (value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)))) {
    const formattedValue = new Intl.DateTimeFormat('nl-NL').format(new Date(value));

    return {
      formattedValue,
      isDateTime: true,
    };
  }

  return {
    formattedValue: value,
    cents: '',
    isDateTime: false,
  };
}

export function formatStripeCurrency(amount) {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: CURRENCY,
  }).format(amount / 100);
}
