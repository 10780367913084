export const industries = {
  label: 'Branche*',
  choices: [
    { value: '- Selecteren -' },
    { value: 'Adviesbureau' },
    { value: 'Architect' },
    { value: 'Bouwbedrijf' },
    { value: 'Fabrikant/producent' },
    { value: 'Gebouwbeheerder' },
    { value: 'Groothandel' },
    { value: 'Installatiebedrijf' },
    { value: 'Onderwijs' },
    { value: 'Overheid' },
    { value: 'Overig' },
  ],
};
