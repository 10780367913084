import { formValidators } from '@forms/validators';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  oldPassword: formValidators.password,
  password: formValidators.password.notOneOf(
    [Yup.ref('oldPassword')],
    'Je ingevulde wachtwoorden zijn niet hetzelfde.',
  ),
  passwordConfirmation: formValidators.passwordConfirmation,
});
