import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const documentDetailsStore = create(
  persist(
    (set) => ({
      documentDetails: { categories: [], documentTypes: [] },
      setDocumentDetails: (documentDetails) => set({ documentDetails }),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
