import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import { formatValue } from '@helpers/formatValue';
import {
  addMonths,
  addYears,
  eachDayOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
  subYears,
} from 'date-fns';
import { nl } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

function DateInput({
  label,
  name,
  register,
  errors,
  message,
  disabled,
  isOpen,
  isValid,
  toggleOpen,
  minDate,
  maxDate,
  onDateChange,
  defaultValue,
  setStartDate,
  setEndDate,
  setOpenStartDate,
  setOpenEndDate,
  openEndDate,
  disableClearButton,
  value,
  expirationDateIsPassedBundleDate,
  organizationBundleExpirationDate,
}) {
  const [selectedDate, setSelectedDate] = useState(defaultValue || null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const formattedDate = selectedDate ? format(selectedDate, 'dd-MM-yyyy') : '';
  const fieldErrors = errors && errors[name];
  const [view, setView] = useState('days');
  const wrapperRef = useRef(null);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (setOpenStartDate) {
            setOpenStartDate(false);
          }

          if (setOpenEndDate) {
            setOpenEndDate(false);
          }
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  const handleNextMonthOrYear = () => {
    if (view === 'days' || view === 'months') {
      setCurrentDate(addMonths(currentDate, 1));
    } else if (view === 'years') {
      setCurrentDate(addYears(currentDate, 1));
    }
  };

  const handlePrevMonthOrYear = () => {
    if (view === 'days' || view === 'months') {
      setCurrentDate(subMonths(currentDate, 1));
    } else if (view === 'years') {
      setCurrentDate(subYears(currentDate, 1));
    }
  };

  const handleMonthClick = () => {
    if (view === 'days') {
      setView('months');
    } else if (view === 'months') {
      setView('years');
    } else {
      setView('days');
    }
  };

  const selectDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      if (!maxDate || date <= maxDate) {
        setSelectedDate(date); // Set the date object directly
        if (onDateChange) onDateChange(date); // Trigger change outside with the date object
        toggleOpen(); // Close the calendar
      }
    }
  };

  const daysInCurrentMonth = eachDayOfInterval({
    start: startOfMonth(currentDate),
    end: endOfMonth(currentDate),
  });

  const datesAreOnSameDay = (first, second) => {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    );
  };

  function handleClearDate() {
    if (disableClearButton) {
      return;
    }

    setSelectedDate(null);
    setCurrentDate(new Date());

    if (setStartDate) {
      setStartDate(null);
    }

    if (setEndDate) {
      setEndDate(null);
    }

    toggleOpen();
  }

  function handleSetTodaysDate() {
    setSelectedDate(new Date());
    setCurrentDate(new Date());

    if (minDate && setStartDate) {
      setStartDate(new Date());
    }

    toggleOpen();
  }

  const DayView = ({ daysInCurrentMonth, minDate, maxDate, selectDate }) => (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-7 gap-2">
        {daysInCurrentMonth.map((day) => {
          const isDisabled = (minDate && day < minDate) || (maxDate && day < maxDate);
          const today = new Date();
          if (!openEndDate && !selectedDate && datesAreOnSameDay(today, day)) {
            return (
              <button
                key={day}
                type="button"
                className="size-8 rounded-full bg-accent p-1 text-center text-primary-dark focus:outline-none"
                onClick={() => selectDate(day)}
              >
                {format(day, 'd', { locale: nl })}
              </button>
            );
          } else if (!openEndDate && selectedDate && datesAreOnSameDay(selectedDate, day)) {
            return (
              <button
                key={day}
                type="button"
                className="size-8 rounded-full bg-accent p-1 text-center text-primary-dark focus:outline-none"
                onClick={() => selectDate(day)}
              >
                {format(day, 'd', { locale: nl })}
              </button>
            );
          }

          return (
            <button
              key={day}
              type="button"
              className={`size-8 rounded-full p-1 text-center focus:outline-none${
                isDisabled
                  ? 'cursor-not-allowed text-gray-300'
                  : 'hover:bg-accent/75 hover:text-primary-dark'
              }`}
              onClick={() => !isDisabled && selectDate(day)}
              disabled={isDisabled}
            >
              {format(day, 'd', { locale: nl })}
              {isDisabled && <Icon iconName="disabled-icon" className="absolute inset-0 m-auto" />}
            </button>
          );
        })}
      </div>
      <hr />
      <div className="flex items-center justify-between px-1 font-bold">
        <button
          type="button"
          className="text-primary-dark hover:text-accent"
          onClick={handleClearDate}
        >
          Wissen
        </button>
        {!openEndDate && (
          <button
            type="button"
            className="text-primary-dark hover:text-accent"
            onClick={handleSetTodaysDate}
          >
            Vandaag
          </button>
        )}
      </div>
    </div>
  );

  const MonthView = ({ currentDate, setCurrentDate }) => (
    <div className="grid grid-cols-3 gap-1">
      {Array.from({ length: 12 }).map((_, monthIndex) => {
        // getMonth() starts at 0 so 1 must be added
        const month = new Date(currentDate.getFullYear(), monthIndex).getMonth() + 1;
        const currentMonth = new Date(currentDate).getMonth() + 1;
        if (month < currentMonth) {
          return (
            <button
              type="button"
              key={monthIndex}
              className="w-full cursor-not-allowed rounded-md p-2 text-center text-gray-300 focus:outline-none"
              disabled={true}
            >
              {format(new Date(currentDate.getFullYear(), monthIndex), 'MMMM', {
                locale: nl,
              })}
            </button>
          );
        }
        return (
          <button
            type="button"
            key={monthIndex}
            onClick={() => {
              setCurrentDate(new Date(currentDate.getFullYear(), monthIndex));
              setView('days');
            }}
            className="w-full rounded-md p-2 text-center hover:bg-accent hover:text-primary-dark focus:outline-none "
          >
            {format(new Date(currentDate.getFullYear(), monthIndex), 'MMMM', {
              locale: nl,
            })}
          </button>
        );
      })}
    </div>
  );

  const YearView = ({ currentDate, setCurrentDate, setView }) => (
    <div className="grid grid-cols-4 gap-1">
      {/* calculate year that needs to be displayed */}
      {Array.from({ length: 12 }).map((_, yearIndex) => {
        const year = currentDate.getFullYear() + yearIndex; // display 5 years before current // Year index shows amount of years in page
        return (
          <button
            type="button"
            key={yearIndex}
            onClick={() => {
              setCurrentDate(new Date(year, currentDate.getMonth()));
              setView('months');
            }}
            className="w-full rounded-md p-3 text-center hover:bg-accent hover:text-primary-dark focus:outline-none"
          >
            {year}
          </button>
        );
      })}
    </div>
  );

  return (
    <div className="relative">
      <label
        htmlFor={name}
        className={classNames(
          'absolute -top-2 left-3 z-10 inline-block bg-white px-1 font-primary text-xs font-light tracking-wide',
          fieldErrors || expirationDateIsPassedBundleDate
            ? 'text-functional-error'
            : isValid || expirationDateIsPassedBundleDate
              ? 'focus-within:text-green-500'
              : 'focus-within:text-accent',
          disabled ? 'opacity-50' : '',
        )}
      >
        {label}
      </label>
      <div className="relative">
        <div className="flex items-center">
          <input
            type="text"
            readOnly
            value={formattedDate}
            onClick={toggleOpen}
            className={classNames(
              'block w-full rounded-md px-4 py-5 font-secondary text-sm tracking-wide text-primary-dark shadow-sm outline-none ring-1 ring-inset ring-[#E1E1E1] placeholder:text-primary-dark/30 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
              fieldErrors || expirationDateIsPassedBundleDate
                ? 'ring-red-500'
                : isValid
                  ? 'ring-green-500'
                  : 'focus:ring-accent',
              disabled ? 'opacity-50' : '',
            )}
            {...(register ? register(name) : {})}
            disabled={disabled}
          />
        </div>

        {isOpen && (
          <div
            className="absolute z-20 mt-2 rounded-md border border-gray-300 bg-white p-2 font-secondary text-sm text-primary-dark shadow-lg"
            ref={wrapperRef}
          >
            <div className="mb-2 flex items-center justify-between px-2 pt-1">
              <div className="flex gap-1">
                <button
                  onClick={handleMonthClick}
                  className="flex items-center gap-1 font-bold focus:outline-none"
                  disabled={disabled}
                  type="button"
                >
                  {view === 'days' && format(currentDate, 'MMMM yyyy', { locale: nl })}
                  {view === 'months' && format(currentDate, 'yyyy', { locale: nl })}
                  {view === 'years' && 'Selecteer jaar'}
                  <div className="text-accent">
                    <Icon iconName="chevron-right" size="xs" />
                  </div>
                </button>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={handlePrevMonthOrYear}
                  className="focus:outline-none"
                  disabled={disabled}
                  type="button"
                >
                  {view !== 'years' && <Icon iconName="chevron-left" size="md" />}
                </button>
                <button
                  onClick={handleNextMonthOrYear}
                  className="focus:outline-none"
                  disabled={disabled}
                  type="button"
                >
                  <Icon iconName="chevron-right" size="md" />
                </button>
              </div>
            </div>
            {view === 'years' && (
              <YearView
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                setView={setView}
              />
            )}
            {view === 'days' && (
              <DayView
                currentDate={currentDate}
                daysInCurrentMonth={daysInCurrentMonth}
                minDate={minDate}
                maxDate={maxDate}
                selectDate={selectDate}
              />
            )}
            {view === 'months' && (
              <MonthView currentDate={currentDate} setCurrentDate={setCurrentDate} />
            )}
          </div>
        )}
      </div>
      {fieldErrors ||
        (expirationDateIsPassedBundleDate && (
          <div className="mt-2 font-primary text-sm font-light tracking-wide text-functional-error">
            {expirationDateIsPassedBundleDate ? (
              <>
                <p>De einddatum kan niet na de einddatum van de organisatie licentie zijn</p>
                <p>
                  De einddatum van de organisatie licentie is{' '}
                  <strong>{formatValue(organizationBundleExpirationDate).formattedValue}</strong>
                </p>
              </>
            ) : (
              message
            )}
          </div>
        ))}
    </div>
  );
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  errors: PropTypes.object,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  defaultValue: PropTypes.instanceOf(Date),
  value: PropTypes.instanceOf(Date),
};

DateInput.defaultProps = {
  register: null,
  errors: null,
  message: '',
  disabled: false,
  minDate: null,
  maxDate: null,
  onDateChange: null,
  defaultValue: null,
  value: null,
};

export default DateInput;
