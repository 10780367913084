import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import PhoneInputField from '@elements/forms/PhoneNumberInput';
import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOrganizationData, updateOrganization } from '@providers/organization';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { validationSchema } from './validation';

export default function AdminSettingsModalForm({
  organizationId,
  setShowAdminModal,
  organization,
  setOrganization,
  phoneValue,
  setPhoneValue,
}) {
  const [isValid, setIsValid] = useState({});

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  function handlePhoneChange(value) {
    if (value.length > 5) {
      setPhoneValue(value);
    }
  }

  async function onSubmit(formData) {
    if (!organization) {
      return;
    }

    const updatedOrganizationPayload = {
      admin_name: formData.adminName,
      admin_email: formData.adminEmail,
    };

    let response = await updateOrganization(organizationId, updatedOrganizationPayload);

    if (response && response.status === 200) {
      response = await getOrganizationData(organizationId);
      if (response && response.status === 200) {
        setOrganization(response.data);
      }
    }
    setShowAdminModal(false);
  }

  return (
    <form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        {/* <InputField
          id="adminName"
          label="Beheerder*"
          name="adminName"
          type="text"
          register={register}
          errors={errors}
          message={errors.adminName?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.adminName);
          }}
          isValid={isValid['adminName']}
          defaultValue={organization?.admin_name || ''}
        /> */}
        {/* TODO get the admin phone value back from backend */}
        <PhoneInputField
          id="adminPhoneNumber"
          label="Beheerder nummer*"
          name="adminPhoneNumber"
          type="tel"
          errors={errors}
          message={errors.adminPhoneNumber?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.adminPhoneNumber);
          }}
          isValid={isValid['adminPhoneNumber']}
          onChange={(e) => handlePhoneChange(e.target.value)}
          phoneInputProps={{
            country: 'nl',
            value: phoneValue,
          }}
        />
        <InputField
          id="adminEmail"
          label="Beheerder e-mail*"
          name="adminEmail"
          type="tel"
          register={register}
          errors={errors}
          message={errors.adminEmail?.message}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.adminEmail);
          }}
          isValid={isValid['adminEmail']}
          defaultValue={organization?.admin_mail || ''}
        />
        {/* TODO ADD PHONE NUMBER FIELD WHEN IT IS IN BACKEND */}
      </div>
      <div className="flex justify-end gap-4">
        <Button
          label="Annuleren"
          type="button"
          buttonType="secondary"
          onClick={() => setShowAdminModal(false)}
        />
        <Button label="Versturen" type="submit" buttonType="primary" />
      </div>
    </form>
  );
}
