import DeleteOrganizationForm from '@forms/DeleteOrganizationForm';
import OrganizationAddressUpdateForm from '@forms/OrganizationAddressUpdateForm';
import Grid from 'components/elements/Grid';
import OrdersView from 'feature/profile/fragment/Orders';
import OrganizationBusinessDetailsForm from 'feature/profile/fragment/OrganizationBusinessSettings';
import SubscriptionView from 'feature/profile/fragment/Subscriptions';

export const OrganizationData = [
  {
    label: 'Adressen',
    href: 'Adressen',
    content: (setSelectedIndex) => (
      <OrganizationAddressUpdateForm setSelectedIndex={setSelectedIndex} />
    ),
  },
  {
    label: 'Bedrijfsgegevens',
    href: 'bedrijfsgegevens',
    content: (setSelectedIndex) => (
      <OrganizationBusinessDetailsForm setSelectedIndex={setSelectedIndex} />
    ),
  },
  {
    label: 'Bestellingen',
    href: 'bestellingen',
    content: (setSelectedIndex) => <OrdersView setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Licentie',
    href: 'licentie',
    content: (setSelectedIndex) => <SubscriptionView setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Profielen',
    href: 'Profielen',
    content: (setSelectedIndex) => <Grid setSelectedIndex={setSelectedIndex} />,
  },
  {
    label: 'Instellingen',
    href: 'Instellingen',
    content: (setSelectedIndex) => <DeleteOrganizationForm setSelectedIndex={setSelectedIndex} />,
  },
];
